//@import url('https://fonts.googleapis.com/css2?family=Familjen+Grotesk:wght@400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
//@import url('https://fonts.cdnfonts.com/css/roobert');

html,
body {
  position: relative;
  box-sizing: border-box;
  text-rendering: optimizeSpeed;
  //font-family: 'Familjen Grotesk', sans-serif;
  //font-family: 'Poppins', sans-serif;
  font-family: 'Inter', sans-serif;
  font-display: optional;
  padding: 0;
  margin: 0;
  //background: #070f1b;
  //background: #08080e;
  background: #0f0f0f;
  color:#f1f1f1;
  overflow: overlay;
  font-style: normal;
  min-height: 100%;
  ::-webkit-scrollbar {
    height: 4px;
    width: 0px;
    background: #00000000;
  }

}


#globalLoader {
  position: fixed;
  z-index: 9999;
  top: 50%;
  left: 50%;
  background-color:  #08080e;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 100vh ;
  height: max-content;
  margin-left: auto;
  margin-right: auto;
  padding-top: 64px;
  padding-bottom: 130px;
  background-color: #0a0a0a;
  //background:#0E141B;
  //background: #121212;
  //background: linear-gradient(180deg, rgba(7, 9, 27, 0.14) -131.19%, #07091B 93.46%);
  //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1100' height='6000' preserveAspectRatio='none' viewBox='0 0 1100 6000'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1010%26quot%3b)' fill='none'%3e%3cpath d='M518.36-863.42C377.93-113.8 699.51 947.77 292.15 2178.83-115.21 3409.89 96.41 3807.1-160.27 4278.83' stroke='rgba(23%2c 203%2c 164%2c 0.5019607843137255)' stroke-width='2'%3e%3c/path%3e%3cpath d='M534.43-945.21C301.55 150.22 579.07 2812.05 157.22 3518.53-264.63 4225.01-18.92 2518.7-219.99 2258.53' stroke='rgba(23%2c 203%2c 164%2c 0.5019607843137255)' stroke-width='2'%3e%3c/path%3e%3cpath d='M712.77-584.65C550.95 329.52 859.81 2642.65 452.69 3119.77 45.57 3596.89 190.33 2043.4-67.46 1799.77' stroke='rgba(23%2c 203%2c 164%2c 0.5019607843137255)' stroke-width='2'%3e%3c/path%3e%3cpath d='M469.29-496.9C286.73 97.35 527.7 955.77 166.94 1971.3-193.82 2986.83 41.89 3350.85-135.41 3771.3' stroke='rgba(23%2c 203%2c 164%2c 0.5019607843137255)' stroke-width='2'%3e%3c/path%3e%3cpath d='M734.95-632.67C492.66 332.17 812.7 1952.72 339.05 3326.78-134.61 4700.84 167.03 4724.08-56.86 5126.78' stroke='rgba(23%2c 203%2c 164%2c 0.5019607843137255)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1010'%3e%3crect width='1100' height='6000' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
}

.contentBasic {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 100vh ;
  margin-left: auto;
  margin-right: auto;
  //background-color: #0a0a0a;
  background-color: #0E1016;
  overflow-x: hidden;
  overflow-y: auto;
  padding-bottom: 130px;

  //background:#0E141B;
  //background: #121212;
  //background: linear-gradient(180deg, rgba(7, 9, 27, 0.14) -131.19%, #07091B 93.46%);
  //background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1100' height='6000' preserveAspectRatio='none' viewBox='0 0 1100 6000'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1010%26quot%3b)' fill='none'%3e%3cpath d='M518.36-863.42C377.93-113.8 699.51 947.77 292.15 2178.83-115.21 3409.89 96.41 3807.1-160.27 4278.83' stroke='rgba(23%2c 203%2c 164%2c 0.5019607843137255)' stroke-width='2'%3e%3c/path%3e%3cpath d='M534.43-945.21C301.55 150.22 579.07 2812.05 157.22 3518.53-264.63 4225.01-18.92 2518.7-219.99 2258.53' stroke='rgba(23%2c 203%2c 164%2c 0.5019607843137255)' stroke-width='2'%3e%3c/path%3e%3cpath d='M712.77-584.65C550.95 329.52 859.81 2642.65 452.69 3119.77 45.57 3596.89 190.33 2043.4-67.46 1799.77' stroke='rgba(23%2c 203%2c 164%2c 0.5019607843137255)' stroke-width='2'%3e%3c/path%3e%3cpath d='M469.29-496.9C286.73 97.35 527.7 955.77 166.94 1971.3-193.82 2986.83 41.89 3350.85-135.41 3771.3' stroke='rgba(23%2c 203%2c 164%2c 0.5019607843137255)' stroke-width='2'%3e%3c/path%3e%3cpath d='M734.95-632.67C492.66 332.17 812.7 1952.72 339.05 3326.78-134.61 4700.84 167.03 4724.08-56.86 5126.78' stroke='rgba(23%2c 203%2c 164%2c 0.5019607843137255)' stroke-width='2'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1010'%3e%3crect width='1100' height='6000' fill='white'%3e%3c/rect%3e%3c/mask%3e%3c/defs%3e%3c/svg%3e");
}


.contentSideBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  min-height: 100vh;
  height: max-content;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 205px;
  background: #0A0A0A;
  padding-left: 55px;

  @media screen and (max-width: 1500px) {
    padding-left: 0px;
    padding-top: 80px;
  }
  
}

div {
    ::-webkit-scrollbar {
      height: 4px;
      width: 4px;
      background: #00000000;
  }

    ::-webkit-scrollbar-thumb {
        background: #ffffff99;
        -webkit-border-radius: 1ex;
        border-radius: 1ex;
        -webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
    }

    ::-webkit-scrollbar-corner {
        background: #00000000;
    }

  @media screen and (max-width: 768px){
    ::-webkit-scrollbar {
        -webkit-appearance: none;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
  body {
    color: white;
    background: black;
  }
}


@mixin backlight($x, $y, $spread, $size, $colorA, $colorB, $duration) {
  &:after {
    position: absolute;
    content: "";
    top: $y;
    left: $x;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    transform: scale($size);
    -webkit-filter: blur($spread);
    -moz-filter: blur($spread);
    -ms-filter: blur($spread);
    filter: blur($spread);
    background: linear-gradient(270deg, $colorA, $colorB);
    background-size: 200% 200%;
    animation: animateGlow $duration ease infinite;
    
    @keyframes animateGlow {
      0%{background-position:0% 50%}
      50%{background-position:100% 50%}
      100%{background-position:0% 50%}
    }
  }
}

.glowingDiv {
  position: relative;
  width: 30vw;
  height: 30vw;
  line-height: 30vw;
  text-align: center;
  color: #252B37;
  background-color: #0a0a0a;
  animation: textColor 10s ease infinite;
  z-index: 100;
  @include backlight(0, 5vw, 5vw, 0.75, #0fffc1, #7e0fff, 10s);
    
  @keyframes textColor {
    0% {
      color: #7e0fff;
    }
    50% {
      color: #0fffc1;
    }
    100% {
      color: #7e0fff;
    }
  }
}

.css-1s34vzp-MuiFormControlLabel-root {
  margin: 0 !important;
  padding: 0  !important;
}

.css-zddqwy-MuiClockNumber-root {
  color: #F1F1F1 !important;
}

.css-53ccts-MuiClockNumber-root{
  color: #F1F1F1 !important;
}


.css-x9nfkm{
  color: #F1F1F1 !important;
}

.css-1flhz3h {
  color: #F1F1F1 !important;
}

.css-vubbuv{
  fill: '#1CAA91' !important;
}

//Swiper 
.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  @media (max-width: 400px)  {
    height: 300px;
}
}

.swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: contain;
  width: 100%;
  
}

.swiper-slide-active {
  opacity: 1;
  z-index: 1;
}

//Swiper 
.swiperCarousell {
  @media (max-width: 400px)  {
    height: 150px;
}}

.swiper-pagination-bullet {
  width: 6px;
  background: transparent;
  border: 1px solid #ffffff;
  opacity: 1;
}

.swiper-pagination-bullet-active {
  width: 20px;
  height: 6px;
  transition: width 0.5s;
  border-radius: 5px;
  background: #ff4d5a !important;
  border: 1px solid transparent;
  
}


blockquote {
  margin: 1rem 0;
  //padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
  color: inherit;
}
blockquote:before {
  color: #f1f1f199;
  content: open-quote;
  font-size: 7em;
  line-height: 0;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
}


.MuiCircularProgress-colorPrimary {
  color: #FF4C5A !important;
}

/*
Box shadow for dark bacground
0px 1.74375px 13.95px rgba(0, 0, 0, 0.1)
*/